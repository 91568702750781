<template>
  <div>
    <h3 class="text-center">People</h3>
    <b-button variant="outline-dark" to="add_people"
      ><b-icon icon="plus"></b-icon>Add</b-button
    >
     <b-button style="float:right" variant="outline-dark" @click="order()"
      ><b-icon icon="plus"></b-icon>Edit Order</b-button
    >

  
             <draggable
              v-if="items"
        :list="items"
        :disabled="!enabled"
        class="draged-cursor row"
        ghost-class="ghost"
        :move="checkMove"
        @start="dragging = true"
        @end="dragging = false"
      >
        <div
          class="col-md-3"
          v-for="(item,index) in items"
          :key="index"
        >
           <b-card
          :title="Date(item.date)"
          :img-src="item.image"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem"
          class="mb-2 news-card"
        >
          <b-card-text class="hideContent">
            {{ item.fullName }} 
          </b-card-text>

          <div class="text-center">
            <b-button
              variant="danger"
              style="float: left"
              @click="globalDelete('People', item.id)"
            >
              <b-spinner
                style="width: 5rem; height: 5rem"
                v-if="loading"
              ></b-spinner>
              Delete</b-button
            >
            <b-button variant="outline-dark" style="float: right;"
    
     @click="routeTo('edit', item)"
      ><b-icon icon="pencil"></b-icon>Edit</b-button
    >
           
          </div>
        </b-card>
        </div>
      </draggable>
  </div>
</template>
<script>
import Vue from "vue";
import draggable from "vuedraggable";
const {imageToBanner,getFields,postData,getFieldsPage,getPerPage, postFields, postDataHeader} = require("../../assets/js/service")
export default {
    components: { draggable },

  data() {
    return {
      fromDate: null,
      toDate: null,
      enabled: true,
      currentPage: 1,
      loading:false,
      rows: 1,
      perPage: 1,
      filterdData:false,
      isBusy: false,
      items: null
    };
  },
  watch:{
      "$store.state.viewReload": {
      handler() {
        if (this.$store.state.viewReload) {
          this.onCreateLoading();
          this.$store.commit("set", ["viewReload", false]);
        }
      },
    },
  },
  mounted() {
    this.onCreateLoading();

    
  },
  methods: {
      checkMove: function(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    order(){
      let token = localStorage.getItem('token');
      this.loading = true;
      let ids = this.items.map(item=>{return {id:item.id}});
      postDataHeader('People/orderPeople',{ids:ids,token}).then(resp=>{
        this.loading =false;
          Vue.$toast.success("Successful", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
      })
    },
    onCreateLoading(){
      let token = localStorage.getItem('token');
   
      getFields('People',token,null,null,{ value: "order", type: "ASC" }).then(resp=>{
 this.items = resp.data;
      })
 
    },
    pageChange(currentPage){
      let token = localStorage.getItem('token');
      getFieldsPage('People',currentPage,token).then(resp=>{
 this.items = resp.data;
      })
    },
    filterByDate(){
      this.filterdData = true;
      let token = localStorage.getItem('token');
      postData('news/filterByDate',{toDate:this.toDate,fromDate:this.fromDate},token).then(resp=>{
        this.items = resp.data.Data;
      })
    },
    routeTo(page, data) {
      this.$store.commit("set", ["singlePageData", data]);
      this.$router.push(page);
    },
     makeBanorimage(img) {
      return imageToBanner("lowQuality", img);
    },
  },
};
</script>
<style>
</style>